<template>
  <div class="mt-32">
    <vue-headful
      title="Settings Plan & Billing | Octoa"
    />
    <v-inner-header
      redirect="/settings/plan-and-billing"
    />
    <form
      action="#"
      class="mx-auto mollie"
      @submit.prevent="upgrade"
    >
      <div class="flex flex-wrap mb-5">
        <div class="w-full px-2">
          <div class="bg-white h-full mx-auto max-w-md px-8 py-8 border-grey-lighter border rounded">
            <div
              v-show="loading"
              class="py-10 text-center"
            >
              <img
                src="@/assets/img/icons/loader.svg"
                width="35"
              >
            </div>
            <div v-show="!loading">
              <h2 class="font-bold mb-2 text-center">
                You picked Octoa <span class="capitalize">{{ form.plan }}</span>
              </h2>
              <div class="text-xl mt-2">
                <div class="text-center text-grey-darker">
                  <span class="text-lg "> Enter your payment info below</span>
                </div>

                <div class="w-full flex flex-wrap mt-8">
                  <div class="w-full text-base font-bold text-black">
                    Credit card details
                  </div>
                  <div class="mollie flex flex-wrap w-full mt-3">
                    <div
                      id="card-holder-group"
                      class="w-full  form-group form-group--cardHolder"
                    >
                      <label
                        class="label"
                        for="card-holder"
                      >Card holder</label>
                      <div id="card-holder" />
                      <div
                        id="card-holder-error"
                        class="field-error"
                        role="alert"
                      />
                    </div>

                    <div class="mt-3   w-full form-group form-group--cardNumber">
                      <label
                        class="label"
                        for="card-number"
                      >Card number</label>
                      <div id="card-number" />
                      <div
                        id="card-number-error"
                        class="field-error"
                        role="alert"
                      />
                    </div>
                    <div class="w-1/2 mt-3  form-group form-group--expiryDate">
                      <label
                        class="label"
                        for="expiry-date"
                      >MM / YY</label>
                      <div
                        id="expiry-date"
                        class="pr-4"
                      />
                      <div
                        id="expiry-date-error"
                        class="field-error"
                        role="alert"
                      />
                    </div>
                    <div class="w-1/2 mt-3 form-group form-group--verificationCode">
                      <label
                        class="label"
                        for="verification-code"
                      >CVV</label>
                      <div id="verification-code" />
                      <div
                        id="verification-code-error"
                        class="field-error"
                        role="alert"
                      />
                    </div>
                  </div>
                  <div class="mt-5 w-full text-base font-bold text-black">
                    Billing details
                  </div>
                  <div class="w-full flex flex-wrap mt-3">
                    <div class="w-full">
                      <input
                        id="name"
                        ref="name"
                        v-model="form.name"
                        v-validate="{ required: true }"
                        class="w-full"
                        type="text"
                        name="name"
                        placeholder="Name"
                      >
                      <span
                        v-if="errors.has('name')"
                        class="error"
                      >{{ errors.first('name') }}</span>
                    </div>
                    <div class="w-full mt-3">
                      <input
                        id="addres"
                        ref="address"
                        v-model="form.address"
                        v-validate="{ required: true }"
                        name="address"
                        class="w-full"
                        type="text"
                        placeholder="Address"
                      >
                      <span
                        v-if="errors.has('address')"
                        class="error"
                      >{{ errors.first('address') }}</span>
                    </div>
                    <div class="w-1/2 mt-3 pr-4">
                      <input
                        id="postal_code"
                        ref="postal_code"
                        v-model="form.postal_code"
                        v-validate="{ required: true }"
                        class="w-full"
                        name="postal"
                        type="text"
                        placeholder="Postal code"
                      >
                      <span
                        v-if="errors.has('postal')"
                        class="error"
                      >{{ errors.first('postal') }}</span>
                    </div>
                    <div class="w-1/2 mt-3">
                      <input
                        id="city"
                        ref="city"
                        v-model="form.city"
                        v-validate="{ required: true }"
                        class="w-full"
                        name="city"
                        type="text"
                        placeholder="City"
                      >
                      <span
                        v-if="errors.has('city')"
                        class="error"
                      >{{ errors.first('city') }}</span>
                    </div>
                    <div class="w-full mt-3">
                      <div class="select-container w-full">
                        <select
                          v-model="form.country"
                          v-validate="'required'"
                          name="country"
                          :tab-index="0"
                          @change="countryChange"
                        >
                          <option value="">
                            Select a country
                          </option>
                          <option
                            v-for="(item, index) in countriesList"
                            :key="index"
                            :value="item.name"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                      <span
                        v-if="errors.has('country')"
                        class="error"
                      >{{ errors.first('country') }}</span>
                    </div>
                    <div class="w-1/2 mt-3 pr-4 ">
                      <input
                        id="vat"
                        ref="vat"
                        v-model="form.vat"
                        class="w-full"
                        name="vat"
                        type="text"
                        placeholder="VAT number (optional)"
                        @input="onCheckVat"
                      >
                    </div>
                    <div class="w-1/2 mt-3">
                      <div class="flex items-center">
                        <div>
                          <input
                            id="vat"
                            ref="vat"
                            v-model="form.discountCode"
                            class="w-full"
                            name="discountCode"
                            type="text"
                            placeholder="Coupon code"
                            :disabled="form.discountCodeId > 0"
                          >
                        </div>
                        <div class="text-center px-4">
                          <a
                            v-show="form.discountCodeId == null"
                            class="text-green pb-1 inline-block cursor-pointer underline text-sm"
                            @click="applyDiscountCode(true)"
                          >
                            Apply
                          </a>
                          <a
                            v-show="form.discountCodeId > 0"
                            class="text-green pb-1 inline-block cursor-pointer underline text-sm"
                            @click="removeDiscountCode"
                          >
                            Remove
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                v-if="!paymentLoading"
                class="green-btn mt-5 w-full rounded"
                type="submit"
              >
                Upgrade my account
              </button>

              <div
                v-if="paymentLoading"
                class="text-center mt-5"
              >
                <img
                  src="@/assets/img/icons/loader.svg"
                  width="35"
                  class="text-center"
                >
              </div>
              <div class="mt-5 mb-2">
                <div
                  v-if="timezone"
                  class="inline-block"
                >
                  <strong>You’ll be charged €<span class="price-text">{{ priceText }}/{{ pricePeriod }}<span v-if="form.includeVat">&nbsp;(including VAT)</span></span> now</strong>.
                </div>
                <div class="mt-5">
                  Cancel any time.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import auth from '@/auth'
import mollie from '@/mollie'
import { debounce } from '@/utils/helpers'

  export default {
    name: 'CreditcardPayment',
    data(){
      return {
        loading: true,
        paymentLoading: false,
        priceText: '',
        pricePeriod: 'year',
        user: auth.user(),
        vatNumberValid: false,
        timezone: '',
        countriesList: {},
        form:{
          plan: 'annually',
          token: '',
          name: '',
          address: '',
          postal_code: '',
          city: '',
          country: '',
          vat: '',
          includeVat: false,
          discountCodeId: null,
          discountAmount: 0,
          discountCode: null,
        }
      }
    },
    created(){

    },
    beforeDestroy(){
      mollie.cardHolder.unmount('#card-holder')
      mollie.cardNumber.unmount('#card-number')
      mollie.expiryDate.unmount('#expiry-date')
      mollie.verificationCode.unmount('#verification-code')
    },
    async mounted(){

      if(this.$route.params.plan != 'annually' && this.$route.params.plan != 'monthly'){
        this.$router.push('/settings/plan-and-billing/')
      }

      if(this.$route.params.plan == 'monthly'){
        this.priceText = ''
        this.pricePeriod = 'month'
      }

      await this.getCountriesList()

      this.form.name = this.user.company.name
      this.form.address = this.user.company.address
      this.form.postal_code = this.user.company.postal_code
      this.form.city = this.user.company.city
      this.form.country = this.user.company.country
      this.form.plan = this.$route.params.plan
      this.form.vat = this.user.companySettings.vat
      this.timezone = this.user.companySettings.timezone

      debounce(async () => {
        await this.checkVat(this.form.country)
      }, 500)()

      mollie.cardHolder.mount('#card-holder')
      const cardHolderError = document.getElementById('card-holder-error')
      mollie.cardHolder.addEventListener('change', event => {
        if (event.error && event.touched) {
          cardHolderError.textContent = event.error
        } else {
          cardHolderError.textContent = ''
        }
      })

      mollie.cardNumber.mount('#card-number')
      const cardNumberError = document.getElementById('card-number-error')
      mollie.cardNumber.addEventListener('change', event => {
        if (event.error && event.touched) {
          cardNumberError.textContent = event.error
        } else {
          cardNumberError.textContent = ''
        }
      })


      mollie.expiryDate.mount('#expiry-date')
      const expiryDateError = document.getElementById('expiry-date-error')

      mollie.expiryDate.addEventListener('change', event => {
        if (event.error && event.touched) {
          expiryDateError.textContent = event.error
        } else {
          expiryDateError.textContent = ''
        }
      })

      mollie.verificationCode.mount('#verification-code')
      const verificationCodeError = document.getElementById('verification-code-error')

      mollie.verificationCode.addEventListener('change', event => {
        if (event.error && event.touched) {
          verificationCodeError.textContent = event.error
        } else {
          verificationCodeError.textContent = ''
        }
      })

      function toggleFieldDirtyClass(fieldName, dirty) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('is-dirty', dirty)
      }

      function toggleFieldFocusClass(fieldName, hasFocus) {
        const element = document.getElementById(fieldName)
        element.parentNode.classList.toggle('has-focus', hasFocus)
      }

      mollie.cardHolder.addEventListener('change', event => toggleFieldDirtyClass('card-holder', event.dirty))
      mollie.cardHolder.addEventListener('focus', () => toggleFieldFocusClass('card-holder', true))
      mollie.cardHolder.addEventListener('blur', () => toggleFieldFocusClass('card-holder', false))

      mollie.cardNumber.addEventListener('change', event => toggleFieldDirtyClass('card-number', event.dirty))
      mollie.cardNumber.addEventListener('focus', () => toggleFieldFocusClass('card-number', true))
      mollie.cardNumber.addEventListener('blur', () => toggleFieldFocusClass('card-number', false))

      mollie.expiryDate.addEventListener('change', event => toggleFieldDirtyClass('expiry-date', event.dirty))
      mollie.expiryDate.addEventListener('focus', () => toggleFieldFocusClass('expiry-date', true))
      mollie.expiryDate.addEventListener('blur', () => toggleFieldFocusClass('expiry-date', false))

      mollie.verificationCode.addEventListener('change', event => toggleFieldDirtyClass('verification-code', event.dirty))
      mollie.verificationCode.addEventListener('focus', () => toggleFieldFocusClass('verification-code', true))
      mollie.verificationCode.addEventListener('blur', () => toggleFieldFocusClass('verification-code', false))

      if(this.user.me.discount_code){
        this.form.discountCode = this.user.me.discount_code
        await this.applyDiscountCode(false)
      }

      this.loading = false
    },
    methods: {
      async applyDiscountCode(notification){
        this.$toasted.clear()
        try {
          const { data } = await this.$api.get('discountCode').apply(this.user.company.id, {discountCode: this.form.discountCode} )
          this.form.discountCodeId = data.id
          this.form.discountAmount = (this.form.plan == 'monthly') ? data.monthly_amount : data.yearly_amount

          this.priceText = (this.form.plan == 'monthly') ? 21.00 : 210.00
          this.priceText -= this.form.discountAmount

          if(this.form.includeVat){
            this.priceText = this.priceText * 1.21
          }
          this.priceText = this.priceText.toFixed(2)

          if(notification){
            this.$toasted.global.general_success({
              message : 'Discount code applied.'
            })
          }

        } catch (e) {
          this.$toasted.global.general_error({
            message : 'Oops, something is wrong with your code.'
          })
        }
      },
      async removeDiscountCode(){
        this.$toasted.clear()

        try {
          const { data } = await this.$api.get('discountCode').remove(this.user.company.id)

          this.form.discountCodeId = null
          this.form.discountAmount = null
          this.form.discountCode = null

          this.priceText = (this.form.plan == 'monthly') ? 21.00 : 210.00
          if(this.form.includeVat){
            this.priceText = this.priceText * 1.21
          }

          this.priceText = this.priceText.toFixed(2)
          this.$toasted.global.general_success({
            message : 'Discount code removed.'
          })
        } catch (e) {
          this.$toasted.global.general_error({
            message : 'Oops, something is wrong.'
          })
        }
      },
      async onCheckVat(){
        debounce(async () => {
          await this.checkVat(this.form.country)
        }, 1000)()
      },
      async checkVat(countryName){
        let that = this
        this.vatNumberValid = false

        const http = new XMLHttpRequest()
        const url = `https://apilayer.net/api/validate?access_key=0e1bcc6d3f622b4ed1111f86e61db3c9&vat_number=${this.form.vat}&format=1`
        http.open('GET', url)
        http.send()

        http.onreadystatechange = function(data){
          if(data.target.readyState == 4 && data.target.status == 200){
            let response = JSON.parse(data.target.response)
            that.vatNumberValid = response.valid
          }

          that.form.includeVat = false
          that.countriesList.forEach(country => {
            if(country.name == that.form.country){
              if(country.name == 'Netherlands' || (country.is_european_union && !that.vatNumberValid)){
                that.form.includeVat = true
              }
            }
          })

          that.priceText = (that.form.plan == 'monthly') ? 21.00 : 210.00
          if(that.form.discountAmount > 0){
            that.priceText -= that.form.discountAmount
          }

          if(that.form.includeVat){
            that.priceText = that.priceText * 1.21
          }

          that.priceText = that.priceText.toFixed(2)
        }
      },
      async countryChange(event){
        debounce(async () => {
          await this.checkVat(event.target.value)
        }, 300)()
      },
      async refresh() {
        this.loading = false
      },
      async getCountriesList() {
        try {
          const { data } = await this.$api.get('country').all()
          this.countriesList = data.countries
        } catch (e) {
          this.$toasted.global.api_error(e)
        }
      },
      async upgrade(){
        const { token, error } = await mollie.orgin.createToken()
        this.form.token = token

        this.$validator.validate().then(result => {
          if(result){
            this.submit()
          }
        })
      },
      async submit(){

        if(this.form.token !== undefined){
          this.paymentLoading = true
          this.$toasted.clear()

          try {
            const { data } =   await this.$api.get('subscription').create(this.user.company.id, this.form)

            await this.$store.dispatch('auth/refreshUser')

            if(data._links.checkout.href){
              window.location.href = data._links.checkout.href
            } else{
              await this.refresh()
              this.$router.push('/settings/plan-and-billing')
            }
          } catch (e) {
            this.$toasted.global.api_error(e)
          }

          this.paymentLoading = false
        }
      },
    },
  }
</script>
